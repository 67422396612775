<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title" tag="h3">
                Movimientos de cuenta
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="movimientos.length > 0">
            <v-list-item
              @click="
                actividad.debe > 0
                  ? $router.push({
                      name: 'ClienteCuentaCorrienteMovimientosDetail',
                      params: { id_comprobante: actividad.comprobante },
                    })
                  : ''
              "
              v-for="actividad in movimientos"
              :key="actividad.id"
            >
              <v-list-item-icon>
                <v-icon>{{
                  actividad.debe > 0 ? "mdi-clock" : "mdi-check"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="actividad.debe > 0 && actividad.deta">
                <v-list-item-title>
                  {{ actividad.deta[0].cantidad }} -
                  {{ actividad.deta[0].descripcion_concepto}}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>
                  {{ actividad.descripcion }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                  v-if="actividad.debe > 0"
                  style="font-size: 1.2rem"
                  tag="h4"
                >
                  - {{ actividad.debe | convertPesoArg }}
                </v-list-item-action-text>
                <v-list-item-action-text
                  v-if="actividad.haber > 0"
                  style="font-size: 1.2rem; color: green"
                  tag="h4"
                >
                  {{ actividad.haber | convertPesoArg }}
                </v-list-item-action-text>
                <v-list-item-action-text>
                  {{
                    fechaMovimiento(actividad.fecha)
                  }}</v-list-item-action-text
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              @click="
                $router.push({ name: 'ClienteCuentaCorrienteMovimientos' })
              "
            >
              <v-list-item-title> Ver todos los movimientos </v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-alert> No se registraron movimientos</v-alert>
          </template>
        </v-list>

        <layout-dialog-delete
          close-dialog="dialogDelete= false"
          @processDelete="eliminarCliente"
          v-if="dialogDelete"
        >
          <template v-slot:title>Eliminar cliente</template>
          <template v-slot:message>
            ¿Desea eliminar el cliente '
            <i>{{ clienteParaEliminar.nombre }}</i> ' ?
          </template>
        </layout-dialog-delete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import ClienteUpdate from "@/views/cliente/ClienteUpdate";

import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";

export default {
  name: "ClienteListCtaCte",
  components: {
    ClienteUpdate,
    LayoutDialogDelete,
  },
  props: {
    search: {
      type: String,
    },
    movimientos: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      cuentasCorrientes: [
        {
          id: 1,
          fecha: "11-01-22",
          comprobabte: "001-NV-0000345",
          debe: "$500.00",
          haber: "$ 0",
          saldo: "$1.000,00",
          vigente: true,
          title: "Compraste 1 Kgs",
        },
        {
          id: 2,
          fecha: "12-01-22",
          comprobabte: "001-RECIBO-0000346",
          debe: "0.000",
          haber: "$ 600.00",
          saldo: "$ -100",
          vigente: true,
          title: "Entregaste $1000",
        },
      ],
      cliente: {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      },
      envio: {
        selected: "",
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      },

      dialogUpdate: false,
      dialogDelete: false,
      clienteParaEliminar: null,
      clienteUpdate: null,
      headers: [
        { text: "Fecha", value: "fecha" },
        { text: "Comprobante", value: "comprobabte" },
        { text: "Debe", value: "debe" },

        { text: "Haber", value: "haber" },

        { text: "Saldo", value: "saldo", sortable: false },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState("cliente", ["clientes"]),
  },
  methods: {
    ...mapActions("cliente", ["eliminar_cliente", "fetchClientes"]),
    ...mapMutations("cliente", ["setClienteSelectedUpdate"]),
    fechaMovimiento(fecha) {
      let date = this.moment(fecha);
      console.log(this.moment().diff(date, "days"));
      if (this.moment().diff(date, "days") > 7) {
        date = date.format("D [de] MMMM"); // '2 days ago' etc.
      } else {
        // datethis.moment(fecha).format('dddd')
        date = date.format("dddd");
      }
      return date;
    },
    processEliminar(cliente) {
      this.dialogDelete = true;
      this.clienteParaEliminar = cliente;
    },
    eliminarCliente() {
      this.eliminar_cliente(this.clienteParaEliminar.id).then((response) => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: `Se eliminó al cliente '${this.clienteParaEliminar.nombre}'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.dialogDelete = false;
      });
    },
    handleClick(a) {
      this.$router.push({
        name: "clientesCuentasCorrientesDetalle",
        params: { id: a.id },
      });
    },
    editarCliente(prop) {
      let cliente = { ...prop };
      this.clienteUpdate = cliente;
      // let prov = { ...prop };
      // this.setClienteSelectedUpdate(prop);

      // this.$router.push({
      //   name: "actualizarCliente",

      //   params: { id: prop.id, cliente: prop },
      // });
      // this.dialogUpdate = true;
    },
  },
  created() {
    if (this.clientes.length == 0) {
      this.fetchClientes();
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
