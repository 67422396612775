<template>
  <span v-if="factura">
    <layout-remito-registrar>
      <template v-slot:title
        ><div>
          <span v-if="factura.nroComprobante">
            {{ factura.nroComprobante }}
          </span>
          <span v-else> Factura ID {{ factura.id }} </span>
        </div>
        <v-list-item>
          <v-list-item-title>
            <v-chip
              v-if="factura.estado_pago === 'Pagado'"
              label
              class="ma-2 green darken-2 pa-2 text-capitalize"
            >
              <v-avatar left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              Pagado
            </v-chip>
            <v-chip
              v-else-if="factura.estado_pago === 'Pendiente de pago'"
              label
              class="ma-2 orange darken-4 pa-2 text-capitalize"
            >
              <v-avatar left>
                <v-icon>mdi-cash</v-icon>
              </v-avatar>
              Pendiente de pago
            </v-chip>
            <v-chip
              v-else-if="factura.estado_pago === 'Parcialmente pagado'"
              label
              class="ma-2 blue darken-2 pa-2 text-capitalize"
            >
              <v-avatar left>
                <v-icon>mdi-timer-sand</v-icon>
              </v-avatar>
              Parcialmente pagado
            </v-chip>
            <v-chip
              v-else
              label
              class="ma-2 grey darken-2 pa-2 text-capitalize"
            >
              <v-avatar left>
                <v-icon>mdi-alert-circle</v-icon>
              </v-avatar>
              Desconocido
            </v-chip>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:navigation-controller>
        <v-container>
          <v-divider class="mt-2"></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-text="'mdi-calendar-range'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="'Fecha de creación'"
              ></v-list-item-title>
              <v-list-item-subtitle>{{
                moment(factura.fechaAlta).format("LL H:mma")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-text="'mdi-account'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Usuario</v-list-item-title>
              <v-list-item-subtitle>{{
                factura.get_user
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="red"
                block
                text
                @click="dialogAnularComprobante = true"
                :disabled="!configuracion.puedeAnularComprobante"
                >Anular comprobante</v-btn
              >
            </v-col>
            <v-dialog v-model="dialogAnularComprobante" persistent width="500">
        <v-card color="#242526">
          <v-card-title>
            <b>
              <div style="font-size: 22px">¿Desea anular el comprobante?</div>
            </b>

            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon color="grey" @click="dialogAnularComprobante = false"
                >mdi-close</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="mt-2">
              <div style="font-size: 16px">Seleccione el motivo</div>
              <div class="text-center mt-2">
                <v-select
                  outlined
                  data-vv-name="motivo"
                  v-validate="'required'"
                  :error-messages="errors.collect('motivo')"
                  v-model="motivoAnulacion.descripcion"
                  :items="motivosAnulacion"
                  item-value="descripcion"
                  item-text="descripcion"
                  label="Motivo"
                >
                </v-select>
                <v-textarea
                  v-if="motivoAnulacion.descripcion == 'Otro'"
                  label="Comentarios"
                  outlined
                  data-vv-name="comentario"
                  v-validate="'max:100'"
                  :error-messages="errors.collect('comentario')"
                  rows="3"
                  counter="100"
                  style="font-size: 25px"
                  row-height="20"
                  v-model="motivoAnulacion.comentario"
                ></v-textarea>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="info" @click="dialogAnularComprobante = false" text>
              <b> Permanecer en la página </b>
            </v-btn>
            <v-btn
              :disabled="
                errors.any() || motivoAnulacion.descripcion == 'Otro'
                  ? motivoAnulacion.comentario
                    ? false
                    : true
                  : false
              "
              @click="validateForm"
              :loading="loadingAnular"
              color="info"
            >
              <b> Anular comprobante </b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
          </v-row>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <FacturaCompraSubtotal
          :detalles="detalles_remito"
          :useBackendData="true"
          :backendTotal="factura.total"
          :backendSubtotal="factura.subtotal"
          :backendPercepcionIva="factura.total_percepcion_iva"
          :backendPercepcionIB="factura.total_percepcion_ingresos_brutos"
          :backendNoGravado="factura.no_gravado"
          :backendTotalVat="factura.total_vat"
        >
        </FacturaCompraSubtotal>
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
          <v-container fill-height fluid>
            <v-row align="center" class="mt-2 mb-2 ml-2 mr-2">
              <v-col cols="12">
                <div class="mb-2">Detalles</div>
                <FacturaCompraDetalleList
                  :edit="false"
                  :withIva="true"
                  :detalles="detalles_remito"
                ></FacturaCompraDetalleList>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:cliente> </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <info-proveedor :proveedor="proveedor"></info-proveedor>
        </v-container>
      </template>
 
    </layout-remito-registrar>
  </span>
</template>

<script>
import LayoutRemitoRegistrar from "@/layouts/layout.registrar.vue";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";
import RemitoCompraDetallesTable from "@/components/compra/remito/remito.compra.detalles.consultar.vue";
import CompraDetalleSubtotal from "@/components/compra/compra.detalle.subtotal.vue";
import FacturaCompraSubtotal from "@/components/compra/factura/FacturaCompraSubtotal.vue";
import FacturaCompraDetalleList from "@/components/compra/factura/FacturaCompraDetallesList.vue";
import FacturaCompraAPI from "@/api/compra/factura";


import { mapActions, mapState } from "vuex";

export default {
  name: "FacturaCompraItemView",
  components: {
    InfoProveedor,
    LayoutRemitoRegistrar,
    FacturaCompraDetalleList,
    CompraDetalleSubtotal,
    FacturaCompraSubtotal,
  },
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      montoActual: 0,
      proveedor: null,
      factura: null,
      sucursal: null,
      nroRemito: null,
      detalles_remito: [],
      motivosAnulacion: [],
      motivoAnulacion: {
        descripcion: "",
        comentario: "",
      },
      dialogAnularComprobante: false,
      loadingAnular: false,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },

  methods: {
    async getMotivosAnulacion() {
      try {
        const response = await this.axios.get("comprobantes/motivosanulacion");
        this.motivosAnulacion = response.data;
      } catch (error) {}
    },
    ...mapActions("remitos", ["registrar_remito_compra"]),

    fetchPedido(id_factura) {
      var url = `/comprobantes/comprobantes/${id_factura}`;

      this.axios.get(url).then((response) => {
        this.factura = response.data;
        this.detalles_remito = response.data.detalles_comprobante;
        this.proveedor = response.data.proveedor;
      });
    },
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.anularComprobante();
        } else {
          this.$validator.validate();
        }
      });
    },
    async anularComprobante() {
      this.loadingAnular = true;

      var id = this.$route.params.id;
      try {
        const response = await FacturaCompraAPI.anular(id);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Factura anulada`,
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialogAnularComprobante = false;
        this.loadingAnular = false;

        this.$router.push("/compras/factura");
      } catch (error) {
        this.loadingAnular = false;
        var message = error.response.data.detail
          ? error.response.data.detail
          : `No se pudo anular la factura.`;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: message,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
  },

  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return router.go(-1);
        } else {
          if (params.pedido) {
            this.fetchPedido(params.id);
          }
          if (params.id) {
            this.fetchPedido(params.id);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created () {
    this.getMotivosAnulacion();
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
