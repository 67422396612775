<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title class="pl-0 pr-0">
        <div class="headline">Cuenta corriente holas</div>

        <v-spacer> </v-spacer>
        <v-btn
          v-if="cuentaCorriente"
          @click="$router.push({ name: 'ClienteCuentaCorrienteSettings' })"
          dark
          icon
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <template v-if="cuentaCorriente">
      <cliente-cuenta-corriente-saldo
        class="mt-4"
        :cuentaCorriente="cuentaCorriente"
        @process-pagar="openPagarCtaCte"
      ></cliente-cuenta-corriente-saldo>
      <v-dialog v-model="dialogClienteCobrar" width="600">
        <cliente-cuentacorriente-pagar
          v-if="dialogClienteCobrar"
          @refresh-cuenta-corriente="refreshCuentaCorrientePago"
          @close-dialog-cobrar="dialogClienteCobrar = false"
          :cuentaCorriente="cuentaCorriente"
        ></cliente-cuentacorriente-pagar>
      </v-dialog>
      <v-dialog v-model="dialogEmpleadoCobrar" width="600">
        <empleado-cuentacorriente-pagar
          @refresh-cuenta-corriente="refreshCuentaCorrientePago"
          @close-dialog-cobrar="dialogEmpleadoCobrar = false"
        ></empleado-cuentacorriente-pagar>
      </v-dialog>
    </template>

    <template v-else>
      <div>
        <v-btn @click="openCuentaCorriente">Abrir cuenta corriente</v-btn>
      </div>
    </template>

    <layout-dialog-delete
      @close-dialog="dialogDelete = false"
      @processDelete="eliminarDirecciones()"
      v-if="dialogDelete"
    >
      <template v-slot:title> Eliminar dirección </template>
      <template v-slot:message>¿Estás eguro que deseas eliminar ? </template>
    </layout-dialog-delete>
  </div>
</template>

<script>
import LayoutDialogDelete from "@/layouts/LayoutDialogDelete";
import ClienteListCtaCteDetalle from "@/components/cliente/ClienteListCtaCteDetalle";
import ClienteCuentaCorrienteSaldo from "@/components/cliente/cuentaCorriente/cliente.cuentacorriente.saldo.vue";
import ClienteCuentacorrientePagar from "@/components/cliente/cuentaCorriente/cliente.cuentacorriente.pagar.vue";
import EmpleadoCuentacorrientePagar from "@/components/empleado/cuentacorriente/empleado.cuentacorriente.pagar.vue";

import ClienteDireccionesAPI from "@/api/clientes/direcciones.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "ClienteCuentaCorriente",
  components: {
    LayoutDialogDelete,
    ClienteListCtaCteDetalle,
    ClienteCuentaCorrienteSaldo,
    ClienteCuentacorrientePagar,
    EmpleadoCuentacorrientePagar,
  },
  data() {
    return {
      dialogDelete: false,
      dialogClienteCobrar: false,
      dialogEmpleadoCobrar: false,
    };
  },
  computed: {
    ...mapState("clienteCuentaCorriente", ["cuentaCorriente"]),
    isEmpleado() {
      return this.$route.path.includes("empleado");
    },
  },

  methods: {
    ...mapActions("clienteCuentaCorriente", [
      "open_cuenta_corriente",
      "deleteCuentaCorriente",
      "getCuentaCorriente",
      "setHabilitado",
    ]),
    openPagarCtaCte() {
      if (this.isEmpleado) {
        this.dialogEmpleadoCobrar = true;
      } else {
        this.dialogClienteCobrar = true;
      }
    },
    closeagarCtaCte() {
      if (this.isEmpleado) {
        this.dialogEmpleadoCobrar = false;
      } else {
        this.dialogClienteCobrar = false;
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    refreshCuentaCorrientePago() {
      this.closeagarCtaCte()
      this.getCuentaCorriente(this.$route.params.id_cliente);
    },
    openCuentaCorriente() {
      const id_cliente = this.$route.params.id_cliente;
      console.log(this.$route.params.id_cliente);
      this.open_cuenta_corriente(id_cliente);
    },
    eliminarCuentaCorriente() {
      const id_cliente = this.$route.params.id_cliente;
      console.log(this.$route.params);

      this.deleteCuentaCorriente(id_cliente);
    },
    setHabilitadoDes() {
      const id_cliente = this.$route.params.id_cliente;

      this.setHabilitado(id_cliente);
    },
  },

  created() {
    const id_cliente = this.$route.params.id_cliente;

    this.getCuentaCorriente(id_cliente);
  },
};
</script>

<style lang="scss" scoped></style>
