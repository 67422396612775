<template>
  <ValidationObserver ref="formDetallePedido">
    <v-card>
      <v-container>
        <v-card-title>
          <div>Agregar detalle</div>
          <v-spacer></v-spacer>
          <v-btn large icon @click="$emit('close-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-list-item three-line>
            <v-list-item-content v-if="detalle.producto">
              <div class="overline mb-4">Nombre</div>
              <v-list-item-title class="headline mb-1"
                ><span class="d-inline-block text-truncate" style="max-width: 280px">{{
                  detalle.producto.descripcion
                }}</span></v-list-item-title
              >
              <v-list-item-subtitle
                >Código: {{ detalle.producto.codigo }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-content v-else>
              <div class="overline mb-4">Nombre</div>

              <v-list-item-title class="headline mb-1"
                ><span class="d-inline-block text-truncate" style="max-width: 280px"
                  >{{ detalle.promocion.nombre }} -
                  {{ detalle.promocion.descripcion }}</span
                ></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-avatar
              tile
              size="100"
              v-if="inventario && detalle.producto && detalle.producto.controlaStock"
            >
              Stock
              {{ detalle.producto.cantidadstock }}
            </v-list-item-avatar>
            <v-list-item-avatar tile size="100">
              <VTextFieldWithValidation
                rules="required|numeric|max:10"
                v-model="detalle.cantidad"
                autocomplete="off"
                min="0"
                autofocus
                name="Cantidad"
                label=" Cantidad"
                hint="Presioná Enter"
                @input="validateStock"
                @keyup.enter.native="validateForm()"
                onFocus="this.select()"
              />
            </v-list-item-avatar>
          </v-list-item>
        </v-card-text>
      </v-container>
    </v-card>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import { mapState } from "vuex";

export default {
  name: "DetallePedidoForm",
  components: { ValidationObserver, VTextFieldWithValidation },
  inject: {
    $validator: "$validator",
  },

  props: {
    detalle: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("sucursales",["configuracion"]),


    inventario() {
      return this.configuracion.productoInventario;
    },
  },
  methods: {
    async reset() {
      this.$nextTick(() => {
        this.$refs.formDetallePedido.reset();
      });
    },
    validateStock(cantidad) {
      if (!this.inventario) {
        return;
      }
      if (this.detalle.promocion) {
        return;
      }
      if (this.detalle.producto.controlaStock && !this.$route.path.includes("compras")) {
        if (parseInt(this.detalle.producto.cantidadstock) < parseInt(cantidad)) {
          this.detalle.cantidad = this.detalle.producto.cantidadstock;
        }
      }
    },
    async validateForm() {
      const result = await this.$refs.formDetallePedido.validate();

      if (result) {
        this.validateStock();
        if (parseInt(this.detalle.cantidad) < 0) {
          return false;
        }
        this.$emit("process-detalle", this.detalle);
      } else {
        this.$validator.validate();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
