<!-- CONTIENE PERMISOS POR MODULOS -->
<template>
  <span>
    <layout-menu-derecha>
      <template v-slot:up-title>Ventas</template>
      <template v-slot:bar-before-spacer> </template>

      <template v-slot:title><h2>Nueva venta</h2></template>
      <template v-slot:bar-extension>
        <v-checkbox
          class="text-center justify-center mt-4"
          @change="setPedidoYA"
          :input-value="esPedidoYa"
          value
        >
          <template v-slot:label>
            <v-img
              src="@/assets/pedidosya/pedidoYa.svg"
              max-height="100"
              max-width="100"
              contain
            ></v-img>
          </template>
        </v-checkbox>
        <v-spacer></v-spacer>
        <agregar-detalle-comprobante
          :fab="false"
          :formatIcon="false"
          :detalles="detallesVenta"
        ></agregar-detalle-comprobante
      ></template>

      <template v-slot:search-barcode>
        <venta-agregar-detalle-barcode
          v-if="!isTablet && !isMobile"
          :detalles="detallesVenta"
        ></venta-agregar-detalle-barcode>
        <DialogMobile v-if="isMobile || isTablet">
          <template v-slot:cliente>
            <!-- <venta-cliente-envio
              v-if="isModuleCliente"
              @set-cliente="setCliente"
              @set-envio="setEsParaEnviar"
            ></venta-cliente-envio> -->

            <VentaClienteDireccionEnvio
              v-if="isModuleCliente"
            ></VentaClienteDireccionEnvio>

            <ClienteManualEnvio
              v-if="!clienteVenta"
              ref="formClient"
              :isOptional="isOptional"
              @activate-is-borrador-venta="activeBorradorVenta = true"
              @process-cliente="setIsValidCliente"
              :validCliente="isValidCliente"
              :formClient="formCliente"
              :formEnvio="formEnvio"
            ></ClienteManualEnvio>

            <div v-if="configuracion.ventaConPedido">
              <seleccionar-pedidos-venta-aprobados
                :fab="false"
                :selectPedido="selectPedido"
                :isSelectPedido="isSelectPedido"
                :pedidos="pedidos_venta_aprobados"
                tipoComprobante="venta"
                @set-remito-sin-pedido="setRemitoSinPedido"
                @reestablecer-detalles="reestablecerDetallesVenta"
                @set-pedido="seleccionarPedido"
                @reset-pedido="resetPedido"
              ></seleccionar-pedidos-venta-aprobados>
            </div>
          </template>
          <template v-slot:detalle-pedido>
            <VentaClienteDireccionEnvio
              v-if="isModuleCliente"
            ></VentaClienteDireccionEnvio>

            <ClienteManualEnvio
              v-if="!clienteVenta"
              ref="formClient"
              :isOptional="isOptional"
              @activate-is-borrador-venta="activeBorradorVenta = true"
              @process-cliente="setIsValidCliente"
              :validCliente="isValidCliente"
              :formClient="formCliente"
              :formEnvio="formEnvio"
            ></ClienteManualEnvio>

            <!-- <comprobante-detalles-list
              :detalles="detallesVenta"
            ></comprobante-detalles-list> -->
            <FacturaVentaDetallesList
              :edit="true"
              :detalles="detallesVenta"
            ></FacturaVentaDetallesList>
          </template>
          <template v-slot:card-actions>
            <v-row>
              <v-col cols="12" md="12" lg="12">
                
                <FacturaVentaSubtotal
                  :tipoRedondeo="'decimales'"
                  :detalles="detallesVenta"
                ></FacturaVentaSubtotal>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row>
              <v-col cols="12" md="12" lg="12">
                <span class="align-end">
                  <v-btn
                    block
                    color="green"
                    :disabled="esValidoRegistrar || errors.any()"
                    @click="dialogCobrar = true"
                    >Cobrar</v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </template>
        </DialogMobile>
      </template>

      <template v-slot:navigation-controller>
        <v-container>
          <div class="mt-4">
            <agregar-detalle-comprobante
              :detalles="detallesVenta"
            ></agregar-detalle-comprobante>
          </div>
        </v-container>
      </template>

      <template v-slot:arriba-vista-previa>
        <v-toolbar v-if="esPedidoYa">
          <v-img
            class="mx-2"
            src="@/assets/pedidosya/pedidoYa.svg"
            max-height="200"
            max-width="200"
            contain
          ></v-img>
        </v-toolbar>

        
        <productos-frecuentes
          :detalles="detallesVenta"
          :fromBuscador="false"
        ></productos-frecuentes>
        <template v-if="isMobile">
          <v-divider></v-divider>
          <VentaClienteDireccionEnvio
            v-if="isModuleCliente"
          ></VentaClienteDireccionEnvio>

          <ClienteManualEnvio
            v-if="!clienteVenta"
            ref="formClient"
            :isOptional="isOptional"
            @process-cliente="setIsValidCliente"
            :validCliente="isValidCliente"
            :formClient="formCliente"
            :formEnvio="formEnvio"
          ></ClienteManualEnvio>

          <v-divider></v-divider>
          <FacturaVentaDetallesList
            :edit="true"
            :detalles="detallesVenta"
            :tipoRedondeo="'decimales'"
          ></FacturaVentaDetallesList>
        </template>
      </template>
      <template v-slot:drawer>
        <drawer :width="'800px'" v-if="!isTablet && !isMobile">
          <template v-slot:cliente>
            <!-- <venta-cliente-envio
              v-if="isModuleCliente"
              @set-cliente="setCliente"
              @set-envio="setEsParaEnviar"
            ></venta-cliente-envio> -->

            <VentaClienteDireccionEnvio
              v-if="isModuleCliente"
            ></VentaClienteDireccionEnvio>

            <ClienteManualEnvio
              v-if="!clienteVenta"
              ref="formClient"
              :isOptional="isOptional"
              @activate-is-borrador-venta="activeBorradorVenta = true"
              @process-cliente="setIsValidCliente"
              :validCliente="isValidCliente"
              :formClient="formCliente"
              :formEnvio="formEnvio"
            ></ClienteManualEnvio>

            <div v-if="configuracion.ventaConPedido">
              <seleccionar-pedidos-venta-aprobados
                :fab="false"
                :selectPedido="selectPedido"
                :isSelectPedido="isSelectPedido"
                :pedidos="pedidos_venta_aprobados"
                tipoComprobante="venta"
                @set-remito-sin-pedido="setRemitoSinPedido"
                @reestablecer-detalles="reestablecerDetallesVenta"
                @set-pedido="seleccionarPedido"
                @reset-pedido="resetPedido"
              ></seleccionar-pedidos-venta-aprobados>
            </div>
          </template>

          <template v-slot:detalle-pedido>
            <!-- <comprobante-detalles-list
              :detalles="detallesVenta"
            ></comprobante-detalles-list> -->
            <FacturaVentaDetallesList
              :edit="true"
              :detalles="detallesVenta"
            :tipoRedondeo="'decimales'"

            ></FacturaVentaDetallesList>
          </template>

          <template v-slot:button-navigation-controller>
            <FacturaVentaSubtotal
              :tipoRedondeo="'decimales'"
              :detalles="detallesVenta"
            ></FacturaVentaSubtotal>
            <v-row class="mt-0">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="align-end">
                  <v-btn
                    block
                    color="green"
                    :disabled="esValidoRegistrar || errors.any()"
                    @click="dialogCobrar = true"
                    >Cobrar</v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </template>
        </drawer>
      </template>
    </layout-menu-derecha>
    <v-dialog width="500" v-model="dialogCobrar">
      <venta-cobrar
        :cliente="formCliente"
        :envio="formEnvio"
        v-if="dialogCobrar"
        ref="ventaCobro"
        :formasDePago="formasDePago"
        :detallesVenta="detallesVenta"
        @process-register="registrarVentaConPedido"
        @close-dialog="dialogCobrar = false"
        :tipoRedondeo="'decimales'"
      ></venta-cobrar>
    </v-dialog>
    <dialog-confirmation-close
      v-if="dialogCancelarPedido"
      @close-dialog="closeDialog"
      @process-accept="discardChanges"
    >
      <template v-slot:titulo>¿Cancelar venta?</template>
      <template v-slot:message
        >¿Seguro que quieres salir? Se perderán todos los cambios.</template
      >
      <template v-slot:buttonOK>Salir de la página</template>
    </dialog-confirmation-close>
  </span>
</template>

<script>
import VentaAgregarDetalleBarcode from "@/components/comprobante/detalle/comprobante.agregar.detalle.barcode.vue";
import AgregarDetalleComprobante from "@/components/comprobante/detalle/comprobante.agregar.detalle.menu.vue";
import ProductosFrecuentes from "@/components/producto/consultar/productos.frecuentes.card.vue";
import Drawer from "@/navigations/Drawer.vue";
import FacturaVentaSubtotal from "@/components/venta/factura/FacturaVentaSubtotal.vue";
import FacturaVentaDetallesList from "@/components/venta/factura/FacturaVentaDetallesList.vue";
//Se utliza si configuracion.menuVentaCruachan esta activo.
import VentaMenuButtons from "@/components/venta/venta.menu.buttons.vue";
import LayoutMenuDerecha from "@/layouts/layout.menu.derecha.vue";
import DialogConfirmationClose from "@/components/dialogs/confirmation.close";
import SeleccionarPedidosVentaAprobados from "@/components/venta/pedido/pedidos.venta.seleccion.aprobados.vue";
import ComprobanteDetallesList from "@/components/venta/detalle/comprobante.detalles.list.vue";
import VentaCobrar from "@/components/venta/cobro/venta.cobrar.vue";
import ClienteManualEnvio from "@/components/cliente/ClienteManualEnvio.vue";

import VentaClienteDireccionEnvio from "@/components/venta/cliente/VentaClienteDireccionEnvio.vue";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import DialogMobile from "@/navigations/DialogMobile.vue";

export default {
  name: "venta.registrar",

  components: {
    FacturaVentaSubtotal,
    FacturaVentaDetallesList,
    AgregarDetalleComprobante,
    VentaAgregarDetalleBarcode,
    LayoutMenuDerecha,
    Drawer,
    VentaMenuButtons,
    ProductosFrecuentes,
    ComprobanteDetallesList,
    SeleccionarPedidosVentaAprobados,
    DialogConfirmationClose,
    VentaCobrar,
    ClienteManualEnvio,
    VentaClienteDireccionEnvio,
    DialogMobile,
  },

  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      formCliente: {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      },
      formEnvio: {
        barrio: null,
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      },
      dialogCarrito: false,
      esParaEnviar: false,
      step: 1,
      checkbox: false,
      pedidoYa: false,

      tipoComprobante: "ventas",
      dialogCobrar: false,
      isOptional: true, // Bandera que hace required al formCliente
      isValidCliente: true, // Bandera para saltar la validacion, se usa en conjunto con isOptional

      selectPedido: null,
      isSelectPedido: true,
      pedidos_venta_aprobados: [],
      activeBorradorVenta: false, //Se activa cuando se modifico el formulario del cliente
      detallesVenta: [],
      dialogCancelarPedido: false,
      finalizar: false,
      to: null,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    ...mapState("remitos", ["esPedidoYa"]),
    ...mapGetters("comprobantes", ["formasDePago"]),
    ...mapGetters("productos", ["loadingProductosFrecuentes"]),
    ...mapState("ventas", ["clienteVenta"]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isTablet() {
      return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.mdOnly; // Identifica tablets
    },
    inventario() {
      return this.configuracion.productoInventario;
    },
    isModuleCliente() {
      return this.configuracion.clientes;
    },

    isValidBorrador() {
      var detalles = this.detallesVenta;
      if (this.activeBorradorVenta || detalles.length > 0) return true;
      return false;
    },
    // esValidoRegistrar() {
    //   return !this.detallesVenta.length > 0;
    // },
    esValidoRegistrar() {
      let detalles = this.detallesVenta.length > 0 ? true : false;
      return detalles && this.isValidCliente ? false : true;
    },
  },
  methods: {
    ...mapMutations("remitos", ["SET_PEDIDO_YA"]),
    ...mapMutations("ventas", [
      "RESET_FORMAS_DE_PAGO_SELECCIONADAS",
      "SET_CLIENTE_VENTA",
    ]),
    ...mapMutations("productos", ["refresh_stock_producto_frecuente"]),
    ...mapActions("ventas", [
      "reset_recargo_descuento_credito",
      "registrar_venta",
    ]),
    ...mapMutations("ventas", ["SET_CLIENTE_VENTA"]),

    ...mapActions("productos", [
      "fetch_productos_pedido_ya",
      "fetch_productos_mas_vendidos",
    ]),
    setIsValidCliente(val) {
      this.isValidCliente = val;
    },

    setPedidoYA() {
      var es = this.esPedidoYa ? false : true;
      this.SET_PEDIDO_YA(es);
      this.detallesVenta = [];
      if (es) {
        this.fetch_productos_pedido_ya();
      } else {
        this.fetch_productos_mas_vendidos();
      }
    },

    stopLoadingVentaCobrar() {
      this.$refs.ventaCobro.registrar();
    },
    registrarVentaConPedido(data) {
      console.log("registrarVentaConPedido", data);
      data.pedido_id = this.selectPedido ? this.selectPedido.id : 0;
      data.nombreEntidad = { cliente: this.formCliente, envio: this.formEnvio };
      data.detalles = this.detallesVenta;
      this.registrar_venta(data)
        .then((response) => {
          this.to = true;
          console.log(response.status);
          console.log(response.data);

          if (response.status == 206) {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: true,
              color: "success",
              text: `Venta registrada con éxito. \nNo  se pudo facturar la venta. Detalle:  ${response.data.error_afip}`,
              icon: "mdi-check",
              timeout: 9000,
              top: true,
              color: "info",
              left: false,
              right: true,
              textButton: "Aceptar",
              multiline: true,
            });
          } else {
            this.$updateBus.$emit("showSnackBar", {
              show: true,
              button: false,
              color: "success",
              text: "Venta registrada",
              icon: "mdi-check",
              timeout: 2000,
            });
          }

          this.$refs.ventaCobro.setLoading(false);

          this.resetPedido();
          this.SET_PEDIDO_YA(false);
          this.fetch_productos_mas_vendidos();
          this.dialogCobrar = false;
          this.$refs.ventaCobro.reset();
          this.SET_CLIENTE_VENTA(null);
          // this.RESET_FORMAS_DE_PAGO_SELECCIONADAS();
          // this.reset_recargo_descuento_credito();
        })
        .catch((e) => {
          this.$refs.ventaCobro.setLoading(false);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            top: true,
            color: "info",
            left: false,
            right: true,
            text: e.response.data["detail"],
            icon: "mdi-information",
            timeout: 3000,
          });
        });
    },
    reestablecerDetallesVenta(pedido) {
      this.fetchPedido(this.selectPedido.id);
    },
    fetchPedido(id) {
      var url = `/pedidos/${id}`;
      this.axios.get(url).then((response) => {
        this.selectPedido = response.data;

        this.detallesVenta = response.data.detalles_pedido;
        if (this.inventario) {
          this.detallesVenta.forEach((detalle) => {
            detalle.producto.cantidadstock -= detalle.cantidad;
            // this.refresh_stock_producto_frecuente(detalle);
          });
        }
        this.formCliente = response.data.cliente.cliente;
        this.formEnvio = response.data.cliente.envio;
      });
    },
    fetchPedidosAprobados() {
      var url = `/pedidos/?esVenta=true&estado=APROBADO`;

      this.axios.get(url).then((response) => {
        this.pedidos_venta_aprobados = response.data;
      });
    },
    setRemitoSinPedido() {
      this.detallesVenta = [];
      this.formCliente = {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      };
      this.formEnvio = {
        barrio: null,
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      };
      this.selectPedido = null;
      this.isSelectPedido = false;
    },
    resetPedido() {
      this.selectPedido = null;
      this.detallesVenta = [];
      this.formCliente = {
        nombre: null,
        telefono: null,
        paraEnviar: false,
      };
      this.formEnvio = {
        barrio: null,
        calle: "",
        altura: "",
        piso: "",
        depto: "",
        observacionEnvio: "",
      };
      this.isSelectPedido = true;
      this.SET_CLIENTE_VENTA(null);
    },
    seleccionarPedido(pedido) {
      this.fetchPedido(pedido.id);
    },
    closeDialog() {
      this.dialogCancelarPedido = false;
      this.to = null;
    },
    discardChanges() {
      this.dialogCancelarPedido = false;
      this.$router.push(this.to);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      event.preventDefault();
      // if (this.dialogCobrar) {
      //   if (event.key === "Enter") {
      //     this.$refs.ventaCobro.registrar();
      //   }
      // }
      if (!this.dialogCobrar && !this.esValidoRegistrar && !this.errors.any()) {
        if (event.key === "F4") {
          this.dialogCobrar = true;
        }
      }
      //Si el dialog_detalle es false, y encontro un producto, levanta el dialogo para agregar la cantidad
    });
  },
  created() {
    this.fetch_productos_mas_vendidos();
    this.$store.commit("SET_LAYOUT", {
      layout: "layout-basic",
      withNav: false,
    });
    if (this.configuracion.ventaConPedido) {
      this.fetchPedidosAprobados();
    }
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return;
        } else {
          if (params.pedido) {
            this.fetchPedido(params.pedido);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    //Para poder salir de la interfaz, tiene que estar seteado 'to' que contiene la siguiente ruta o estar en true

    if (this.to) {
      if (this.finalizar) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Venta registrada",
          icon: "mdi-check",
          timeout: 2000,
        });
      }
      next();
    } else {
      this.SET_CLIENTE_VENTA(null);

      // seteo to con la siguiente ruta

      //Si el isValidBorrador esta desactivado (false) sale sin activar la confirmacion
      //Permite salir al ver que no se realizo nada
      !this.isValidBorrador ? next() : (this.dialogCancelarPedido = true);
      this.to = to;
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
.v-dialog__content {
  align-items: flex-start;
  margin-top: 4vh;
}
</style>
