<template>
  <v-container>
    <loading
      :active.sync="loadingProductosFrecuentes"
      :is-full-page="false"
      background-color="transparent"
      color="#FA0050"
    ></loading>
    <v-row v-show="!loadingProductosFrecuentes">
      <v-col
        v-for="producto in productos_mas_vendidos"
        :key="producto.id"
        cols="12"
        md="4"
        lg="3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-card
              hover
              color="'grey darken-1'"
              @click="validate(producto)"
              v-on="on"
              class="mx-auto"
              height="100"
              :disabled="
                inventario &&
                producto.controlaStock &&
                producto.cantidadstock <= 0
              "
            >
              <v-card-title v-if="esPedidoYa" class="ma-0 mt-1 pa-0">
                <v-spacer></v-spacer>
                <v-img
                  src="@/assets/pedidosya/pedidoYa.svg"
                  max-height="20"
                  max-width="20"
                  contain
                ></v-img>
              </v-card-title>
              <v-card-text class="my-4 mt-1 text-center">
                <div class="text-truncate text-h5">
                  {{ producto.descripcion }}
                </div>
                <div class="text-h6">
                  {{ producto.precioVenta | convertPesoArg }}
                </div>
                <div>
                  <v-chip
                    small
                    v-if="
                      inventario &&
                      producto.controlaStock &&
                      producto.cantidadstock <= 0
                    "
                    color="warning"
                    >Sin stock</v-chip
                  >
                </div>
                <div>
                  <v-chip
                    small
                    v-if="
                      inventario &&
                      producto.controlaStock &&
                      producto.cantidadstock > 0
                    "
                    color="info"
                    >{{ producto.cantidadstock }}</v-chip
                  >
                </div>
              </v-card-text>
            </v-card>
          </template>
          <span>{{ producto.descripcion }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { add_detalle_update } from "@/services/detallesComprobante/serviceDetallesUpdate";
import { addDetalle } from "@/services/detallesComprobante/serviceDetalles";
import { mapState, mapGetters } from "vuex";
export default {
  name: "productos.frecuentes.card",
  components: {
    Loading,
  },

  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    detalles: {
      type: Array,
      required: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    fromBuscador: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      productos: [],
    };
  },
  computed: {
    ...mapState("productos", ["productos_mas_vendidos"]),
    ...mapState("remitos", ["esPedidoYa"]),
    ...mapGetters("productos", ["loadingProductosFrecuentes"]),
    ...mapState("sucursales", ["configuracion"]),

    inventario() {
      return this.configuracion.productoInventario;
    },
  },
  methods: {
    validate(producto) {
      if (this.fromBuscador) {
        this.agregarProducto(producto);
      } else {
        this.agregarDetalle(producto);
      }
    },
    agregarProducto(producto) {
      this.$emit("process-producto", producto);
    },
    agregarDetalle(producto) {
      console.log(producto);
      
      var detalle = { cantidad: 1, precioUnitario: producto.precioBase,descripcion_concepto: producto.descripcion, producto: producto, vat_type: producto.iva,  isProducto: true, };
      var det = { ...detalle };

      this.isBorrador = true;
      if (this.isUpdate) {
        add_detalle_update(this.detalles, det);
      } else {
        var response = addDetalle(this.detalles, det);
      }
      if (response) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "info",
          text: `${response}`,
          icon: "mdi-information",
          timeout: 1500,
        });
      } else {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          right: false,
          top: false,
          color: "success",
          text: "Producto agregado",
          icon: "mdi-check",
          timeout: 1500,
        });
      }
    },
  },
};
</script>

<style scoped>
.detalle {
  margin: 0;
  padding: 0;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}
</style>
