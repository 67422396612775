<template>
  <v-navigation-drawer
    v-if="pedido"
    width="400px"
    app
    v-model="modelComputed"
    temporary
    right
  >
    <template v-slot:prepend>
      <v-alert dense class="mb-0" :color="statusColor(pedido)">
        {{ pedido.getEstadosActual }}
      </v-alert>

      <v-list-item class="ma-0">
        <v-list-item-content>
          <v-list-item-title>
            <div class="display-1">
              Pedido nº. {{ pedido.numeroVentaPorArqueo }}
            </div>
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            {{ pedido.numero }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon
          ><v-btn outlined @click="editarPedido(pedido)">
            Editar
            <v-icon class="mr-2">mdi-pencil</v-icon>
          </v-btn></v-list-item-icon
        >
      </v-list-item>
      <v-spacer></v-spacer>

      <!-- <v-list class="pa-0" dense>
        <v-list-item dense>
          <v-list-item-avatar class="mb-0 mt-0"
            ><v-icon left>mdi-account</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-subtitle class="caption">
              Cliente
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ pedido.cliente.cliente.nombre }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-avatar class="mb-0 mt-0"
            ><v-icon left>mdi-phone</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-title>
              {{ pedido.cliente.cliente.telefono }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->

      <!-- <pedido-detalle-actualizar
          :disabledDetalle="true"
          v-if="detalles_pedido.length > 0"
          :detalles="detalles_pedido"
        ></pedido-detalle-actualizar> -->
    </template>
    <pedido-detalle-list :detalles="detalles_pedido"></pedido-detalle-list>

    <v-dialog width="500" v-model="dialogCobrar">
      <PedidoVentaCobrar
        :cliente="cliente"
        :formasDePago="formasDePago"
        :envio="envio"
        v-if="dialogCobrar"
        ref="ventaCobro"
        :tipoRedondeo="'entero'"
        :detallesVenta="detalles_pedido"
        @process-register="pagarPedido"
        @close-dialog="dialogCobrar = false"
      ></PedidoVentaCobrar>
    </v-dialog>
    <template v-slot:append>
      <v-divider></v-divider>
      <pedido-detalle-subtotal
        :detalles="detalles_pedido"
        :tipoRedondeo="'entero'"
      ></pedido-detalle-subtotal>
      <!-- <v-list-item v-show="cliente.paraEnviar">
        <v-list-item-avatar>
          <v-icon color="indigo">mdi-truck-delivery</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Para enviar</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-list-item-subtitle
            >{{ envio.calle }} {{ envio.altura }}</v-list-item-subtitle
          >
        </v-list-item-icon>
      </v-list-item> -->
      <pedido-estados-siguientes
        ref="refEstadosSiguientes"
        @process-refresh="refreshPedido"
        :pedido="pedido"
        @process-cobrar="dialogCobrar = true"
        @process-registrar="registrarVentaConPedido"
        @process-quitar-pedido="quitarPedido"
        @close-dialog="cerrarDialogo"
      ></pedido-estados-siguientes>
    </template>
  </v-navigation-drawer>
</template>

<script>
import PedidoDetalleActualizar from "@/components/pedido/actualizar/pedido.detalle.actualizar.vue";
import PedidoDetalleSubtotal from "@/components/pedido/actualizar/pedido.detalle.subtotal.vue";
import PedidoDetalleList from "@/components/pedido/actualizar/pedido.detalle.list.vue";
import PedidoEstadosSiguientes from "@/components/pedido/actualizar/pedido.estados.siguientes.vue";

import PedidoVentaCobrar from "@/components/pedido/venta/PedidoVentaCobro.vue";

import registrar_venta from "@/services/ventas/registrarVenta.js";
import PedidoApi from "@/api/venta/pedido";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "pedido.venta.cobrar",
  components: {
    PedidoDetalleActualizar,
    PedidoDetalleSubtotal,
    PedidoDetalleList,
    PedidoVentaCobrar,
    PedidoEstadosSiguientes,
  },
  props: {
    id_pedido: {
      type: Number,
      required: true,
    },
    model: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogPedidoCancelar: false,
      loadingButton: false,
      pedido: null,
      initCantidadDetalles: null,
      detalles_pedido: null,
      estado: null,
      tipoEntrega: null,
      fechaEntregaEstimada: null,
      cliente: null,
      envio: null,
      dialogCobrar: false,
      estadosSiguientes: null,
    };
  },
  computed: {
    ...mapGetters("comprobantes", ["formasDePago"]),

    modelComputed: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit("set-drawer", val);
      },
    },
    pedidosSiguientes() {
      var pedidos = [];
      this.pedido.getEstadosSiguientesDescripcion.forEach((element) => {
        if (element.accion) {
          pedidos.push(element);
        }
      });
      return pedidos;
    },
  },
  methods: {
    ...mapActions("ventas", [
      "registrar_venta",
    ]),

    quitarPedido(pedido) {
      this.$emit("process-quitar-pedido", pedido);
      this.cerrarDialogo();
    },
    refreshPedido(estado) {
      this.$emit("process-refresh", estado);
      this.cerrarDialogo();
    },
    editarPedido(pedido) {
      this.$emit("process-ver-pedido", pedido);
    },
    cerrarDialogo() {
      this.$emit("close-dialog");
    },
    async pagarPedido(dataVentaCobro) {
      try {
        var response = await PedidoApi.pagar(this.pedido.id, dataVentaCobro);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Pedido finalizado",
          icon: "mdi-check",
          timeout: 2000,
        });
        
        console.log(dataVentaCobro);
        
        this.registrarVentaConPedido();
        this.dialogCobrar = false;
        this.$refs.ventaCobro.setLoading(false);
        this.$refs.ventaCobro.reset(false);
      } catch (e) {
        this.$refs.ventaCobro.setLoading(false);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          top: true,
          color: "info",
          left: false,
          right: true,
          text: e.response.data,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
    registrarVentaConPedido() {
      console.log(this.pedido);
      
      var data = {
        pedido_id: this.pedido.id,
        detalles: this.detalles_pedido,
      };
      console.log(data);
      
      this.registrar_venta(data)
        .then((response) => {
          this.to = true;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Pedido cobrado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.$emit("process-refresh", this.estado);
          this.$emit("close-dialog");
          this.dialogCobrar = false;
        })
        .catch((e) => {
          console.log(e);
          
          console.log(e.response.status);
          var message = ''
          if (e.response.status === 500) {
            message = 'Problema inesperado - 500'
          }else{
            message = e.response.data
          }
          
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            top: false,
            color: "info",
            left: true,
            right: false,
            text: message,
            icon: "mdi-information",
            timeout: 3000,
          });
          this.$refs.refEstadosSiguientes.setLoading(false);
          this.$refs.ventaCobro.setLoading(false);
          console.log(e.response.data);
          
        });
    },
    statusColor(pedido) {
      switch (pedido.getEstadosActual) {
        case "PENDIENTE":
          return "warning";
          break;
        case "APROBADO":
          return "primary";
          break;
        default:
          "grey";
      }
    },

    verPedido() {
      this.$emit("process-ver-pedido", this.pedido);
    },

    fetchPedido(id) {
      var url = `/pedidos/venta/${id}`;

      this.axios.get(url).then((response) => {
        this.initCantidadDetalles = response.data.detalles_pedido.length;
        this.pedido = response.data;
        this.detalles_pedido = response.data.detalles_pedido;
        this.estado = response.data.getEstadosActual;
        this.tipoEntrega = response.data.tipoEntrega;
        this.fechaEntregaEstimada = response.data.fechaEntregaEstimada;

        this.estadosSiguientes = response.data.getEstadosSiguientesDescripcion;
      });
    },
  },
  watch: {
    model(val) {
      if (!val) {
        this.$emit("close-dialog");
      }
    },
  },

  created() {
    this.fetchPedido(this.id_pedido);
  },
};
</script>

<style scoped>
#buttonAccion {
  text-transform: lowercase;
}

#buttonAccion > span {
  display: inline-block;
}

#buttonAccion > span:first-letter {
  text-transform: uppercase;
}
</style>
