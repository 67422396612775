<template>
  <div class="items3">
    <v-snackbar color="error" v-model="error_detalle_repetido.state" multi-line>
      {{ error_detalle_repetido.message }}
    </v-snackbar>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Use v-if to conditionally render based on screen size -->
    <v-container v-if="$vuetify.breakpoint.mdAndUp">
      <v-data-table
        style="background-color: transparent"
        :headers="computedHeaders"
        :items="detalles"
        sort-by="calories"
        no-data-text="No hay productos agregadoss ok"
        hide-default-footer
        disable-pagination
        disable-sort
        dense
      >
        <template v-slot:item.cantidad="{ item }">
          <v-text-field
            v-model.number="item.cantidad"
            append-outer-icon="mdi-plus"
            type="number"
            @input="handleInput(item)"
            maxLength="6"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            min="0"
            height="2px"
            dense
            single-line
            hide-spin-buttons
            outlined
            hide-details
            onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
            class="centered-input mobile mt-0"
          >
            <template v-slot:prepend>
              <v-btn
                @click="decrementCant(item)"
                elevation="2"
                outlined
                icon
                small
              >
                <v-icon small>mdi-minus</v-icon>
              </v-btn>
            </template>
            <template v-slot:append-outer>
              <v-btn
                @click="incrementCant(item)"
                elevation="2"
                small
                outlined
                icon
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>
        <template v-slot:item.vat_type="{ item }">
          <AlicuotaIvaSelect
            :isEdit="true"
            class="mt-1 mb-1"
            :iva="item.vat_type"
            @update:iva="setIva(item, $event)"
          ></AlicuotaIvaSelect>
        </template>
        <template v-slot:item.subtotal="{ item }">
          {{ calcularSubtotal(item) | convertPesoArg }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="eliminarDetalle(item)" v-on="on" icon>
                <v-icon color="red">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <!-- Mobile view with v-list -->
    <v-container v-else>
      <v-list>
        <v-subheader>Detalle de venta</v-subheader>
        <v-list-item
          v-for="(item, index) in detalles"
          :key="index"
          class="detalle"
          two-line
        >
          <v-list-item-avatar>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="red"
                  small
                  @click="eliminarDetalle(item)"
                  v-on="on"
                  icon
                >
                  <v-icon color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </v-list-item-avatar>
          <v-list-item-avatar>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{
              item.descripcion_concepto
            }}</v-list-item-title>

            <v-list-item-subtitle>
              <a>Editar</a>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text tag="h2">
              <h2>
                {{ calcularSubtotal(item) | convertPesoArg }}
              </h2></v-list-item-action-text
            >
            <v-text-field
              v-model.number="item.cantidad"
              type="number"
              @input="handleInput(item)"
              maxLength="6"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              min="0"
              height="2px"
              dense
              single-line
              hide-spin-buttons
              outlined
              hide-details
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              class="centered-input mt-2"
            >
              <template v-slot:prepend-inner>
                <v-btn
                  @click="decrementCant(item)"
                  elevation="2"
                  outlined
                  icon
                  small
                >
                  <v-icon small>mdi-minus</v-icon>
                </v-btn>
              </template>
              <template v-slot:append>
                <v-btn
                  @click="incrementCant(item)"
                  elevation="2"
                  small
                  outlined
                  icon
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <!-- <v-btn @click="eliminarDetalle(item)" icon>
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn> -->
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import {
  quitDetalle,
  updateDetalle,
} from "@/services/detallesComprobante/serviceDetalles";
import { mapState } from "vuex";
import ComprobanteDetalleForm from "@/components/comprobante/detalle/comprobante.detalle.form.vue";
import ComprobanteDetalleButtons from "@/components/comprobante/detalle/comprobante.detalle.buttons.vue";
import AlicuotaIvaSelect from "@/components/util/AlicuotaIvaSelect.vue";

export default {
  name: "FacturaVentaDetallesList",
  components: {
    ComprobanteDetalleForm,
    ComprobanteDetalleButtons,
    AlicuotaIvaSelect,
  },
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    tipoRedondeo: {
      type: String,
      required: true,
      default: "entero", // Valor predeterminado
      validator: (value) => {
        return ["decimales", "entero"].includes(value);
      },
    },
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  data() {
    return {
      snack: false,
      snackColor: "",
      snackText: "",
      dialog_detalle: false,
      detalle: null,
      detalle_update: {},
      height: "100%",
      headers: [
        { text: "Cantidad", value: "cantidad", width: "15%" },
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "descripcion_concepto",
        },
        { text: "Precio uni.", value: "precioUnitario", width: "15%" },
        {
          text: "Impuestos",
          value: "vat_type",
          align: "start",
        },
        { text: "Precio Total", value: "subtotal", width: "15%" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapState("pedidos", ["error_detalle_repetido"]),
    ...mapState("sucursales", ["configuracion"]),
    computedHeaders() {
      if (!this.edit) {
        return this.headers.filter((header) => header.text !== "Acciones");
      }
      return this.headers;
    },
  },
  methods: {
    handleInput(item) {
      // Limitar a 6 dígitos
      if (item.cantidad.length > 6) {
        item.cantidad = item.cantidad.slice(0, 6);
      }

      // Si el campo está vacío, asignar 0
      if (!item.cantidad) {
        item.cantidad = 1;
      }
      const maxCantidad = this.getMaxCantidad(item);
      if (item.cantidad > maxCantidad) {
        item.cantidad = maxCantidad;
      }

      // Recalcular el subtotal
      this.calculateSubtotal(item);
    },
    getMaxCantidad(item) {
      if (
        this.configuracion.productoInventario &&
        item.producto &&
        item.producto.controlaStock
      ) {
        return item.producto.cantidadstock;
      }
      return Infinity; // No hay límite si no se controla el stock
    },
    getIvaValue(vat_type) {
      const iva_description = vat_type.description || ""; // Asegura que sea un string
      let iva = 0; // Inicializa IVA en 0 por defecto

      if (iva_description.toLowerCase() === "exento") {
        iva = 0;
      } else {
        const parsedIva = parseFloat(iva_description);
        iva = isNaN(parsedIva) ? 0 : parsedIva; // Si no es un número válido, asigna 0
      }
      return iva;
    },

    calcularSubtotal(item) {
      console.log(item.precioUnitario);
      console.log(typeof item.precioUnitario);

      
      // Obtén los valores como números flotantes
      const precioBase = parseFloat(parseFloat(item.precioUnitario).toFixed(2));
      const cantidad = parseFloat(item.cantidad);

      let iva = this.getIvaValue(item.vat_type);

      // Calcula el precio unitario con IVA
      let precioConIva;

      if (iva > 0) {
        // Calcula el precio con IVA y redondea a 2 decimales
        precioConIva = parseFloat((precioBase * (1 + iva / 100)).toFixed(2));
      } else {
        // Sin IVA, el precio base es el precio con IVA
        precioConIva = precioBase;
      }
      console.log(precioConIva);

      // Calcula el subtotal multiplicando por la cantidad
      let subtotal = parseFloat((precioConIva * cantidad).toFixed(2));
      console.log("Subtotal con IVA:", subtotal);

      // Aplica el tipo de redondeo según 'tipoRedondeo'
      console.log("Tipo de redondeo:", this.tipoRedondeo);

      if (this.tipoRedondeo === "entero") {
        subtotal = Math.round(subtotal); // Redondea al entero más cercano
      } else if (this.tipoRedondeo === "decimales") {
        subtotal = parseFloat(subtotal.toFixed(2)); // Redondea a dos decimales
      }

      console.log("Subtotal final:", subtotal);

      return subtotal;
    },
    setIva(detalle, vatType) {
      detalle.vat_type = vatType;
    },
    closeCantidad(item) {
      if (!item.cantidad) {
        item.cantidad = 1;
      }
      if (item.cantidad == 0) {
        item.cantidad = 1;
      }
      if (item.cantidad < 0) {
        item.cantidad = item.cantidad * -1;
      }
    },
    incrementCant(detalle) {
      var cantidad = parseFloat(detalle.cantidad); // Convertir a número

      const isPizza =
        detalle.producto &&
        detalle.producto.categoriaArbol.name.includes("PIZZA");
      const canIncreaseStock =
        detalle.producto &&
        this.configuracion.productoInventario &&
        detalle.producto.controlaStock;
      const hasSufficientStock =
        detalle.producto && detalle.producto.cantidadstock > cantidad;

      console.log("canIncreaseStock", canIncreaseStock);
      console.log("hasSufficientStock", hasSufficientStock);

      if (detalle.producto) {
        if (canIncreaseStock) {
          if (hasSufficientStock) {
            cantidad += isPizza ? 0.5 : 1; // Sumar correctamente como número
          }
        } else {
          cantidad += isPizza ? 0.5 : 1;
        }
      } else {
        cantidad++;
      }

      // Asignar de vuelta el valor numérico convertido
      detalle.cantidad = cantidad;
    },
    decrementCant(detalle) {
      var cantidad = parseFloat(detalle.cantidad);
      if (detalle.producto) {
        if (detalle.producto.categoriaArbol.name.includes("PIZZA")) {
          if (cantidad == 0.5) {
            return;
          }
          cantidad -= 0.5;
          return;
        } else {
          if (cantidad == 1) {
            return;
          }
          cantidad--;
        }
      } else {
        if (cantidad == 1) {
          return;
        }
        cantidad--;
      }
      detalle.cantidad = cantidad;

    },
    eliminarDetalle(detalle) {
      console.log(detalle);
      quitDetalle(this.detalles, detalle);
    },
    modificarDetalle(detalle) {
      this.dialog_detalle = false;
      if (
        this.configuracion.productoInventario &&
        detalle.producto.controlaStock
      ) {
        detalle.producto.cantidadstock -= parseInt(detalle.cantidad);
      }
      updateDetalle(this.detalles, detalle);
    },
    setDetalle(detalle) {
      let detalle_pedido = { ...detalle };
      this.detalle_update = detalle_pedido;
      this.dialog_detalle = true;
    },
  },
  watch: {
    dialog_detalle(val) {
      if (val == false) (this.detalle = null), (this.detalle_update = {});
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      event.preventDefault();
      if (event.key === "Escape") {
        this.detalle = null;
      }
      if (event.keyCode === 46 && this.dialog_detalle == false) {
        if (this.detalle !== null) {
          event.preventDefault();
          this.eliminarDetalle(this.detalles[this.detalle]);
          this.detalle = null;
        }
      }
      if (event.keyCode === 13) {
        if (this.detalles.length > 0) {
          if (this.detalle !== null && this.dialog_detalle == false) {
            this.setDetalle(this.detalles[this.detalle]);
          }
        }
      }
    });
  },
};
</script>

<style scoped>
.remove-active-hover.v-btn--active::before {
  opacity: 0;
}
.v-input__slot input {
  text-align: center;
  direction: ltr;
}
/deep/ .v-input .v-input__prepend-outer {
  margin-right: 0 !important;
}
/deep/ .v-input .v-input__append-outer {
  margin-left: 0 !important;
}

/deep/ .v-text-field .mobile {
  width: 120px;
}
/deep/ .v-text-field {
  width: 110px;
}

.v-btn--outlined {
  border: thin solid #575555;
  border-width: 0.01mm;
}
::v-deep .v-data-table.row-height-50 tr {
  height: 10px !important;
}

::v-deep .v-data-table.row-height-50 td {
  height: 26px !important;
}

.centered-input >>> input {
  text-align: center;
}
</style>
