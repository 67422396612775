<template>
  <div>
    <v-card>
      <v-container>

      <v-card-title>
        <span class="headline">Remitos de Compra Pendientes</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="remito in remitos"
            :key="remito.id"
            @click="selectRemito(remito)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ remito.codigo }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ moment(remito.fechaAlta).format("lll") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-pagination
              v-if="remitos"
              @input="paginationChangeHandler"
              :length="pages"
              :value="paginacion.page"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>

    </v-card>
  </div>
</template>

<script>
import RemitoApi from "@/api/compra/remito";

export default {
  name: "RemitosCompraPendientes",
  data() {
    return {
      remitos: [],
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 }
    }
  },
  computed: {
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    selectRemito(remito) {
      this.$emit("select-remito", remito);
    },
    fetchRemitosPendientes(){
      RemitoApi.getRemitosPendientes(this.paginacion)
      .then((response) => {
        this.remitos = response.data.results;
        this.paginacion.cantidad = response.data.count;

      })
      .catch((error) => {
        console.error(error);
      });
    },
    setPage(page) {
  
      this.loading = true;
      this.paginacion.page = page;

     this.fetchRemitosPendientes()
    },
    paginationChangeHandler(pageNumber) {
      if (this.paginacion.page == pageNumber) {
        return false;
      }
      this.setPage(pageNumber);
    },
  },
  created() {
    this.fetchRemitosPendientes()
  },

};
</script>
