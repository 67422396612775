<template>
  <div>
    <v-dialog v-model="dialogFacturar" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="blue"
          v-bind="attrs"
          v-on="on"
          @click="dialogFacturar = true"
        >
          Facturar <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
      </template>
      <v-card color="#242526" v-if="!preImpreso">
        <v-card-title>
          <div style="font-size: 20px"><b>Elige el tipo de facturación</b></div>

          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon color="grey" @click="dialogFacturar = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
            class="d-flex align-center justify-center mb-6"
            style="height: 100%"
          >
            <v-row justify="center">
              <v-col>
                <v-row class="mt-1" justify="center">
                  <v-col cols="4">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="registrarFacturaElectronica"
                        :loading="loadingFacturar"
                        height="150px"
                        width="150px"
                        class="d-flex align-center"
                        :color="hover ? '#313233' : ''"
                      >
                        <v-container class="text-center">
                          <div>
                            <v-icon color="blue" large>mdi-tag</v-icon>
                          </div>
                          <h3>Factura electrónica</h3>
                          <span style="color: #bdbdbd"> </span>
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col cols="4">
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="preImpreso = true"
                        height="150px"
                        width="150px"
                        class="d-flex align-center"
                        :color="hover ? '#313233' : ''"
                      >
                        <v-container class="text-center">
                          <div>
                            <v-icon color="blue" large>mdi-tag-multiple</v-icon>
                          </div>
                          <h3>Pre-impreso</h3>
                          <span style="color: #bdbdbd">
                            Asocia una factura en papel con la venta.
                          </span>
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <factura-pre-impresa
        v-if="preImpreso"
        :comprobante="comprobante"
        @go-back="preImpreso = false"
        @close-dialog="dialogFacturar = false"
      ></factura-pre-impresa>
    </v-dialog>
    <v-dialog v-model="dialogConsultarPreImpreso" width="500">
      <factura-pre-impresa-consultar
        v-if="dialogConsultarPreImpreso"
        :comprobante="comprobante"
        @close-dialog="dialogConsultarPreImpreso = false"
      ></factura-pre-impresa-consultar>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VentasApi from "@/api/venta/venta";
import FacturaPreImpresa from "@/components/venta/factura/venta.factura.pre-impreso.form.vue";
import FacturaPreImpresaConsultar from "@/components/venta/factura/venta.factura.pre-impreso.consultar.vue";

export default {
  name: "VentaFacturarComprobante",
  components: {
    FacturaPreImpresa,
    FacturaPreImpresaConsultar,
  },
  $_veeValidate: {
    validator: "new",
  },
  props: {
    comprobante: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogConsultarPreImpreso: false,
      preImpreso: false,
      dialogFacturar: false,
      loadingFacturar: false
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    async registrarFacturaElectronica() {
      this.loadingFacturar = true;
      try {
        await VentasApi.facturar(this.comprobante.id);

        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Comprobante facturado`,
          icon: "mdi-check",
          timeout: 3000,
        });
        this.dialogFacturar = false;

        // this.$router.push("/ventas");
        this.$emit('success:factura', this.comprobante.id)
      } catch (error) {
        console.log(error);
        this.loadingFacturar = false;
        var message = error.response.data.detail
          ? error.response.data.detail
          : `Hubo un problema en la facturación`;
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "red",
          text: message,
          icon: "mdi-information",
          timeout: 3000,
        });
      }
    },
   
  },
};
</script>

<style lang="scss" scoped>
</style>