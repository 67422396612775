<template>
  <v-card flat id="endPage">
    <v-container>
      <v-row>
        <v-col>
          <producto-buscador
            ref="search"
            @process-buscador-producto="buscarProductos"
            @process-buscador-producto-select="buscarProductos"
            :categorias="categoriasFiltro"
          >
          </producto-buscador>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          
          <v-toolbar v-if="categoria_padre" flat color="transparent">
            <v-btn
              @click="buscarProductosPorCategoriaPrincipal(categoria_padre.name)"
              icon
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title>{{ categoria_seleccionada }}</v-toolbar-title>
          </v-toolbar>
          <v-sheet v-if="categoriasFiltro.length == 0" class="mx-auto" max-width="700">
            <v-slide-group multiple show-arrows>
              <v-slide-item v-for="categoria in categorias" :key="categoria.id">
                <v-btn
                  class="mx-2"
                  depressed
                  rounded
                  @click="buscarProductosPorCategoria(categoria.name)"
                >
                  {{ categoria.name }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
          <v-sheet v-else class="mx-auto" max-width="700">
            <v-slide-group multiple show-arrows>
              <v-slide-item v-for="categoria in categoriasFiltro" :key="categoria">
                <v-btn
                  class="mx-2"
                  depressed
                  rounded
                  @click="buscarProductosPorCategoria(categoria)"
                >
                  {{ categoria }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <productos-list-card-select
              :paginacion="paginacion"
              v-if="productos.length > 0"
              :productos="productos"
              :isCompra="isCompra"
              @process-producto="agregarProducto"
            ></productos-list-card-select>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ProductosListCardSelect from "@/components/producto/listar/productos.list.card.select.vue";
import ProductoBuscador from "@/components/producto/buscador/producto.buscador.vue";

import ProductoBuscadorMultiple from "@/components/producto/buscador/producto.buscador.multiple";
import { mapState } from "vuex";
import ProductosAPI from "@/api/productos";
import CategoriasAPI from "@/api/categorias";


export default {
  name: "producto.buscador.menu",
  components: {
    ProductoBuscadorMultiple,
    ProductosListCardSelect,
    ProductoBuscador,
  },
  props:{
    categoriasFiltro: {
      type: Array,
      required: false,
      default: () => []
    },
    isCompra: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reloadCategoria: 0,
      productos: [],
      categorias: [],
      categoria_padre: null,
      categoria_seleccionada: null,
      loadingBusqueda: false,

      paginacion: { page: 1, cantidad: 0, rowsPerPage: 12 },

      tab: null,
      items: [
        { tab: "Categorias", content: "Marca" },
        { tab: "Búsqueda avanzada", content: "Precio compra" },
        { tab: "Promociones", content: "Precio compra" },
      ],
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
  },
  methods: {
    async buscarProductos(data) {
      const response = await ProductosAPI.index(data, this.paginacion);
      this.productos = response.data.results;
    },
    async buscarProductosPorCategoriaPrincipal(categoria){
      if (this.categoriasFiltro.length > 0){
        categoria = this.categoriasFiltro[0]
      }
      const response = await ProductosAPI.fetchProductosPorCategoria(
        categoria,
        this.paginacion
      );
      this.productos = response.data.results;
      if (response.data.categorias) {
        this.categorias = response.data.categorias;
        this.categoria_seleccionada = response.data.categoria;
        this.categoria_padre = response.data.categoria_padre;
      }
    },
    async buscarProductosPorCategoria(categoria) {
      const response = await ProductosAPI.fetchProductosPorCategoria(
        categoria,
        this.paginacion
      );
      this.productos = response.data.results;
      if (response.data.categorias) {
        this.categorias = response.data.categorias;
        this.categoria_seleccionada = response.data.categoria;
        this.categoria_padre = response.data.categoria_padre;
      }
    },
    fetchCategoriaRoot() {
      CategoriasAPI.index().then((response) => {
        this.categorias = response.data.parent.children;
      });
    },
    agregarProducto(producto) {
      this.$emit("process-producto", producto);
      this.$refs.search.focus();
    },

    enviarDetalle() {
      this.$emit("process-add-detalle-pedido", this.detalle);
      this.$emit("close-dialog");

      this.reloadCategoria = !this.reloadCategoria;

      this.$refs.detalleForm.reset();
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.key === "Insert") {
        this.$nextTick(() => {
          this.$refs.search.$el.focus();
        });
      }
    });
  },
  created() {
    this.fetchCategoriaRoot();
  },
};
</script>

<style scoped></style>
