<template>
  <layout-registrar-producto v-if="producto">
    <template v-slot:up-title>
      <span></span>
    </template>

    <template v-slot:title>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-title>
            <h1 style="font-size: 30px" class="text-wrap">
              {{ producto.descripcion }}
            </h1>
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-subtitle>
              Actualizado el
              {{ moment(producto.fechaActualizacion).format("ll") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!producto.tiene_contabilidad" class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <v-alert type="info" text outlined>
                <div class="text-wrap">

                  "Este producto no tiene contabilidad asociada. Actualícelo para crear automáticamente el registro contable."
                </div>

              </v-alert>
            </v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-list>
        </v-list>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <b> Stock disponible</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div v-if="producto.controlaStock">
                {{ producto.cantidadstock }}
              </div>
              <div v-else>Sin control</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="pa-0"
          v-if="producto.parent && configuracion.productosFraccionados"
        >
          <v-list-item-content>
            <v-list-item-title>
              <div class="text-wrap">Es producto de</div>
            </v-list-item-title>
            <v-list-item-subtitle>
              <b>
                <span
                  ><a @click="changeParam(producto.parent)">
                    {{ producto.parent_name.toUpperCase() }}</a
                  ></span
                ></b
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </template>
    <template v-slot:navigation-controller>
      <v-container>
        <v-btn
          v-show="step == 1"
          large
          class="mb-2"
          color="primary"
          block
          @click="
            $router.push({
              name: 'actualizarProducto',
              params: { id: producto.id, producto: producto },
            })
          "
        >
          <b style="font-size: 17px">Modificar </b>
        </v-btn>
        <v-btn
          v-show="step == 1"
          large
          class="mb-2 mt-2"
          text
          color=""
          block
          @click="
            $router.push({
              name: 'productoItemHistorial',
              params: { id: producto.id, producto: producto },
            })
          "
        >
          <v-icon>mdi-history</v-icon>
          <b style="font-size: 17px">Historial </b>
        </v-btn>
        <v-btn
          v-if="!producto.parent && configuracion.productosFraccionados"
          v-show="step == 1"
          large
          class="mb-2"
          color="success"
          block
          @click="
            $router.push({
              name: 'añadirProductoFraccionado',
              params: { id: producto.id, producto: producto },
            })
          "
        >
          <b style="font-size: 17px">Añadir producto fraccionado </b>
        </v-btn>
      </v-container>
    </template>
    <template v-slot:navigation-append>
      <v-container>
        <!-- <v-btn v-show="step == 1" small class="mb-2" color="error" block
                    @click="$router.push({ name: 'actualizarProducto', params: { id: producto.id, producto: producto } })">
                    <b >Eliminar
                    </b>
                </v-btn> -->
      </v-container>
    </template>
    <template v-slot:vista-previa>
      <v-row align="center" justify="center" class="mt-2 mx-auto">
        <v-col cols="10">
          <v-card>
            <v-container v-if="!loadingFetch">
              <v-row align="center" justify="center" class="mx-auto">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-img
                        contain
                        src="@/assets/no-image.png"
                        height="300px"
                        dark
                      >
                      </v-img>
                      <v-row class="mt-4">
                        <v-col>
                          <v-divider></v-divider>
                          <div class="mt-4 title">
                            Características principales
                          </div>
                          <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr v-if="marca">
                                  <td><strong>Marca</strong></td>
                                  <td>{{ marca.nombre }}</td>
                                </tr>
                                <tr v-if="categoria">
                                  <td><strong>Categoría</strong></td>
                                  <td>{{ categoria.name }}</td>
                                </tr>
                                <tr v-if="proveedor">
                                  <td><strong>Proveedor</strong></td>
                                  <td>{{ proveedor.nombre }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <h1 style="font-size: 30px">
                                {{ producto.descripcion }}
                              </h1>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ producto.codigoDeBarra }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="font-size: 40px">{{
                                producto.precioVenta | convertPesoArg
                              }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-list>
                        <v-list-item v-if="producto.esPedidoYa">
                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="font-size: 30px">{{
                                precioPY | convertPesoArg
                              }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-avatar>
                            <v-img
                              src="@/assets/pedidosya/pedidoya.png"
                              max-height="30"
                              max-width="30"
                              contain
                            ></v-img>
                          </v-list-item-avatar>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> Precio costo</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title">
                                {{ producto.precioCosto | convertPesoArg }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> IVA Compra</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title"> {{ producto.iva_compra.description }}% </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> Precio de Compra</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title"> {{ producto.precio_compra | convertPesoArg }} </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> Ganancia</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title">
                                {{ producto.ganancia }}%
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> IVA</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title"> {{ producto.iva.description }}% </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <b> Flete</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="title"> {{ producto.flete }}% </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  v-if="
                    producto.fraccion && configuracion.productosFraccionados
                  "
                >
                  <div class="title">
                    Productos fraccionados
                    <span>- {{ productosFraccionados.length }}</span>
                  </div>
                  <v-sheet class="mx-auto">
                    <v-slide-group
                      class="pa-3"
                      active-class="success"
                      show-arrows
                    >
                      <v-slide-item
                        width="50px"
                        color="grey"
                        v-for="p in productosFraccionados"
                        :key="p.id"
                      >
                        <template v-if="producto.fraccion">
                          <v-card @click="changeParam(p.id)" class="mx-auto">
                            <producto-item :producto="p"></producto-item>
                          </v-card>
                        </template>
                        <h2 v-else>
                          No tienes productos fraccionados
                        </h2>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </layout-registrar-producto>
</template>
  
<script>
import { administrarMixin } from "@/mixins/producto/administrarMixin.js";
import LayoutRegistrarProducto from "@/layouts/productos/layout.registrar.producto";
import ProductoVistaPrevia from "@/components/producto/producto.vista.previa.vue";

import FormProductoProveedor from "@/components/producto/form/form.producto.proveedor.vue";
import FormProductoCategoria from "@/components/producto/form/form.producto.categoria.vue";
import FormProductoDescripcion from "@/components/producto/form/form.producto.descripcion.vue";
import FormProductoBarcode from "@/components/producto/form/form.producto.barcode.vue";

import ProductoForm from "@/components/producto/form/producto.form";
import FormProductoMarca from "@/components/producto/form/form.producto.marca.vue";
import FormProductoPrecioCosto from "@/components/producto/form/form.producto.precio.costo.vue";
import FormProductoGanancia from "@/components/producto/form/form.producto.ganancia.vue";
import FormProductoIva from "@/components/producto/form/form.producto.iva.vue";
import FormProductoFlete from "@/components/producto/form/form.producto.flete.vue";
import FormProductoPrecioVenta from "@/components/producto/form/form.producto.precio.venta.vue";
import FormProductoInventario from "@/components/producto/form/form.producto.inventario.vue";

import CalculadoraPrecioVenta from "@/components/producto/actualizar/producto.calculadora.precioVenta.vue";
import ProductoItem from "@/components/producto/producto.item.vue";

import { mapActions, mapState } from "vuex";
import ProductosAPI from "@/api/productos";

export default {
  name: "ProductoItemHome",
  components: {
    ProductoItem,
    FormProductoCategoria,
    FormProductoDescripcion,
    FormProductoBarcode,
    ProductoForm,
    LayoutRegistrarProducto,
    FormProductoProveedor,
    FormProductoMarca,
    FormProductoPrecioCosto,
    FormProductoGanancia,
    FormProductoIva,
    FormProductoFlete,
    FormProductoPrecioVenta,
    ProductoVistaPrevia,
    FormProductoInventario,
    CalculadoraPrecioVenta,
  },
  $_veeValidate: {
    validator: "new",
  },
  mixins: [administrarMixin],
  data() {
    return {
      loadingFetch: false,
      producto: null,
      dialogCalculator: false,
      loadingProductosFraccionados: false,
      productosFraccionados: null,
    };
  },
  computed: {
    ...mapState("sucursales", ["configuracion"]),
    precioPY() {
      return Math.round(
        this.producto.precioVenta +
          (this.producto.precioVenta * this.producto.porcentajePY) / 100
      );
    },
  },

  methods: {
    ...mapActions("productos", [
      "actualizar_producto",
      "fetch_productos_mas_vendidos",
    ]),
    fetchProductosFraccionados(producto_id) {
      this.loadingProductosFraccionados = true;

      ProductosAPI.showProductosFraccionados(producto_id)
        .then((response) => {
          this.productosFraccionados = response.data;
          this.loadingProductosFraccionados = true;
        })
        .catch((e) => {
          console.log(e);

          this.loadingProductosFraccionados = false;
        });
    },
    openCalculator() {
      this.dialogCalculator = !this.dialogCalculator;
    },
    validateForm(nextStep) {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          if (nextStep) {
            this.nextStep(nextStep);
          } else {
            this.actualizarProducto(this.producto);
          }
        } else {
          this.$validator.validate();
        }
      });
    },
    actualizarProducto(p) {
      this.loading = true;

      this.actualizar_producto(p)
        .then((response) => {
          // this.update_product(data);
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "success",
            text: "Producto Actualizado",
            icon: "mdi-check",
            timeout: 2000,
          });
          this.loading = false;
          this.fetch_productos_mas_vendidos();
          this.$router.go(-1);
        })
        .catch((e) => {
          this.loading = false;
          this.$updateBus.$emit("showSnackBar", {
            show: true,
            button: false,
            color: "info",
            text: "Problema inesperado",
            icon: "mdi-information",
            timeout: 3000,
          });
          this.loading = false;
        });
    },

    setPrecioCosto(precio) {
      this.producto.precioCosto = precio;
    },
    setGanancia(precio) {
      this.producto.ganancia = precio;
    },

    fetchProducto(id) {
      this.loadingFetch = true;

      ProductosAPI.show(id).then((response) => {
        this.producto = response.data;
        this.producto.categoriaArbol
          ? null
          : (this.producto.categoriaArbol = { id: null });
        this.setCategoria(response.data.categoriaArbol);
        this.setMarca(response.data.marca);
        this.setProveedor(response.data.proveedor);
        this.loadingFetch = false;
      });
    },
    changeParam(id) {
      this.$router.replace({ name: "productoHome", params: { id: id } });
      this.fetchProducto(id);
      this.fetchProductosFraccionados(id);
    },
  },
  created() {
    if (this.$route.params.producto === undefined) {
      this.fetchProducto(this.$route.params.id);
      this.fetchProductosFraccionados(this.$route.params.id);
    } else {
      this.loadingFetch = true;
      var producto = this.$route.params.producto;
      if (!producto.categoriaArbol) {
        producto.categoriaArbol = { id: null };
      }

      this.setCategoria(producto.categoriaArbol);
      this.setMarca(producto.marca);
      this.setProveedor(producto.proveedor);

      producto.categoriaArbol = producto.categoriaArbol
        ? producto.categoriaArbol
        : { id: null };
      this.producto = producto;
      this.fetchProductosFraccionados(producto.id);

      this.loadingFetch = false;
    }
  },
};
</script>
  
<style scoped>
.items3:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}

.items3 ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}

.items3 ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}

.items3 ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
  