<template>
  <LayoutBasic :showLinksMenu="false" :routeBack="'/compras'">
    <template v-slot:breadcrumbs>
      <v-breadcrumbs class="pa-3 ml-1" :items="navigation" divider=">">
        <v-breadcrumbs-item
          slot="item"
          slot-scope="{ item }"
          exact
          :to="item.to"
          >{{ item.text }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>
    <template v-slot:navigation-title>Remitos de compra </template>

    <template v-slot:navigation-content>
      <v-list>
        <v-list-item-group v-model="item" color="blue lighten-3">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="goToRoute(item.route)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:vista-previa>
      <v-container>
        <remito-consultar-lista
          :remitos="remitos"
          @set-page="setPage"
          :paginacion="paginacion"
          @abrir-vista-previa="abrirVistaPrevia"
        ></remito-consultar-lista>
        <v-dialog v-model="dialogVistaPrevia" width="800px">
          <remito-compra-vista-previa
            v-if="remito"
            :remito="remito"
            @refresh="fetchRemitos"
            @close-dialog="dialogVistaPrevia = false"
          ></remito-compra-vista-previa>
        </v-dialog>
      </v-container>

      <router-view></router-view>
    </template>
  </LayoutBasic>

</template>

<script>
import RemitoConsultarLista from "@/views/compra/remitos/remito.consultar.lista.vue";
import LayoutBasic from "@/layouts/LayoutBasic.vue";

import RemitoCompraVistaPrevia from "@/views/compra/remitos/remito.compra.vista-previa.vue";

import { getStorage, saveStorage } from "@/services/serviceLocalStorage";
import { get_remitos_compra } from "@/services/comprobante.js";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "remito.compra",
  components: {
    RemitoCompraVistaPrevia,
    RemitoConsultarLista,
    LayoutBasic,
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },
  data() {
    return {
      item: null,
      loading: false,
      remitos: [],
      remito: null,
      dialogVistaPrevia: false,
      refresh_remitos: 0,
      paginacion: { page: 1, cantidad: 0, rowsPerPage: 10 },
    };
  },
  computed: {
    borradoresCompra() {
      return getStorage("borradoresRemitosCompra");
    },
    borradoresVenta() {
      return getStorage("borradoresRemitosVenta");
    },
    navigation() {
      return [
        {
          text: "Compras",
          disabled: false,
          to: { name: "compras" },
        },
        {
          text: "Remito",
          disabled: false,
          to: { name: "remitoCompra" },
        },
      ];
    },
    items() {
      return [
        {
          text: "Nuevo remito",
          icon: "mdi-clock",
          route: "nuevoRemitoCompra",
        },
        {
          text: "Consultar",
          icon: "mdi-account",
          route: "remitosConsultar",
        },
      ];
    },
    pages() {
      if (this.paginacion.rowsPerPage == null || this.paginacion.cantidad < 0) {
        return 0;
      }

      return Math.ceil(this.paginacion.cantidad / this.paginacion.rowsPerPage);
    },
  },
  methods: {
    ...mapActions("remitos", ["fetch_remitos"]),
    ...mapMutations("remitos", [
      "set_pedido_venta_register",
      "set_pedido_compra_register",
    ]),
    abrirVistaPrevia(remito) {
      this.remito = remito;
      this.dialogVistaPrevia = true;
    },

    setPage(page) {
      var esVenta = false;
      if (
        this.tipoComprobante == "compras" ||
        this.tipoComprobante == "compra"
      ) {
        esVenta = false;
      } else {
        esVenta = true;
      }
      this.loading = true;
      this.paginacion.page = page;
      var url = `/comprobantes/comprobantes?esVenta=${esVenta}&doc=RE&page=${this.paginacion.page}&page_size=${this.paginacion.rowsPerPage}`;

      this.axios
        .get(url)
        .then((response) => {
          this.loading = false;
          this.remitos = response.data.results;
          this.paginacion.cantidad = response.data.count;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setBorradorRemitoVenta(item) {
      var cliente = item.cliente;
      var envio = item.envio;
      var detalles = item.detalles;
      var i = this.borradoresVenta.indexOf(item);

      if (i !== -1) {
        this.borradoresVenta.splice(i, 1);
      }

      localStorage.setItem(
        "borradoresRemitosVenta",
        JSON.stringify(this.borradoresVenta)
      );
      this.set_pedido_venta_register({
        cliente,
        envio,
        detalles,
      });
      this.goToRoute("nuevoRemitoVenta");
    },
    setBorradorRemitoCompra(item) {
      var proveedor = item.proveedor;
      var detalles = item.detalles;
      var i = this.borradoresCompra.indexOf(item);

      if (i !== -1) {
        this.borradoresCompra.splice(i, 1);
      }
      localStorage.setItem(
        "borradoresRemitosCompra",
        JSON.stringify(this.borradoresCompra)
      );
      this.set_pedido_compra_register({
        proveedor,
        detalles,
      });
      this.goToRoute("nuevoRemitoCompra");
    },
    goToRoute(route) {
      this.$router.push({ name: route }).catch(() => {});
    },
    async fetchRemitos() {
      try {
        this.loading = true;
        var response = await get_remitos_compra(this.paginacion);
        this.remitos = response.data.results;
        this.paginacion.cantidad = response.data.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchRemitos();
  },
};
</script>

<style lang="scss" scoped></style>
