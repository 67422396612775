<template>
  <v-list>
    <v-list-item dense>
      <v-list-item-content>
        <v-list-item-title>Cantidad artículos</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>{{ detalles.length }} </v-list-item-icon>
    </v-list-item>
    <v-list-item v-if="showSubTotal">
      <v-list-item-content>
        <v-list-item-title>Subtotal</v-list-item-title>
      </v-list-item-content>

      <v-list-item-icon
        ><h2>{{ Number(subtotal()) | convertPesoArg }}</h2>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "compra.detalle.registrar.subtotal",
  props: {
    detalles: {
      type: Array,
      required: true,
    },
    showSubTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    subtotal() {
      var subtotal = 0;
      for (let index = 0; index < this.detalles.length; index++) {
        if (this.detalles[index].producto) {
          var iva = 0;
          var precio =
            this.detalles[index].cantidad * this.detalles[index].precioUnitario;

          iva =
            (precio *
              parseFloat(this.detalles[index].vat_type.description)) /
            100;
          var precioConIva = precio + iva;
          subtotal += precioConIva;
        } else {
          var precio =
            this.detalles[index].cantidad * this.detalles[index].precioUnitario;
          subtotal += precio;
        }
      }
      return subtotal;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>