var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items3"},[_c('v-snackbar',{attrs:{"color":"error","multi-line":""},model:{value:(_vm.error_detalle_repetido.state),callback:function ($$v) {_vm.$set(_vm.error_detalle_repetido, "state", $$v)},expression:"error_detalle_repetido.state"}},[_vm._v(" "+_vm._s(_vm.error_detalle_repetido.message)+" ")]),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-container',[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.computedHeaders,"items":_vm.detalles,"sort-by":"calories","no-data-text":"No hay productos agregadoss ok","hide-default-footer":"","disable-pagination":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"centered-input mobile mt-0",attrs:{"append-outer-icon":"mdi-plus","type":"number","maxLength":"6","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","min":"0","height":"2px","dense":"","single-line":"","hide-spin-buttons":"","outlined":"","hide-details":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)"},on:{"input":function($event){return _vm.handleInput(item)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{attrs:{"elevation":"2","outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.decrementCant(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"elevation":"2","small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.incrementCant(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]},proxy:true}],null,true),model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", _vm._n($$v))},expression:"item.cantidad"}})]}},{key:"item.vat_type",fn:function(ref){
var item = ref.item;
return [_c('AlicuotaIvaSelect',{staticClass:"mt-1 mb-1",attrs:{"isEdit":true,"iva":item.vat_type},on:{"update:iva":function($event){return _vm.setIva(item, $event)}}})]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(_vm.calcularSubtotal(item)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.eliminarDetalle(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,false,2990247271)})],1):_c('v-container',[_c('v-list',[_c('v-subheader',[_vm._v("Detalle de venta")]),_vm._l((_vm.detalles),function(item,index){return _c('v-list-item',{key:index,staticClass:"detalle",attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.eliminarDetalle(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-cart")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.descripcion_concepto))]),_c('v-list-item-subtitle',[_c('a',[_vm._v("Editar")])])],1),_c('v-list-item-action',[_c('v-list-item-action-text',{attrs:{"tag":"h2"}},[_c('h2',[_vm._v(" "+_vm._s(_vm._f("convertPesoArg")(_vm.calcularSubtotal(item)))+" ")])]),_c('v-text-field',{staticClass:"centered-input mt-2",attrs:{"type":"number","maxLength":"6","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","min":"0","height":"2px","dense":"","single-line":"","hide-spin-buttons":"","outlined":"","hide-details":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)"},on:{"input":function($event){return _vm.handleInput(item)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{attrs:{"elevation":"2","outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.decrementCant(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-btn',{attrs:{"elevation":"2","small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.incrementCant(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)]},proxy:true}],null,true),model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", _vm._n($$v))},expression:"item.cantidad"}})],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }