<template>
  <v-card outlined>
    <div class="d-flex align-center justify-center" style="height: 100%">
      <v-row>
        <v-col cols="8">
          <v-card color="#3A3B3C">
            <v-img contain src="@/assets/no-image.png" height="500px" dark>
            </v-img>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-title>
                <v-container>
                  <span class="text-wrap text-left" style="font-size: 30px"
                    ><strong>{{
                      producto.descripcion ? producto.descripcion : "Nombre"
                    }}</strong></span
                  >
                </v-container>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="font-size: 40px">{{
                    precioVenta | convertPesoArg
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ producto.codigoDeBarra }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list v-if="producto.esPedidoYa">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="font-size: 30px">{{
                    precioPY | convertPesoArg
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-img
                  src="@/assets/pedidosya/pedidoya.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-if="marca">
                  <td><strong>Marca</strong></td>
                  <td>{{ marca.nombre }}</td>
                </tr>
                <tr v-if="categoria">
                  <td><strong>Categoría</strong></td>
                  <td>{{ categoria.name }}</td>
                </tr>
                <tr v-if="proveedor">
                  <td><strong>Proveedor</strong></td>
                  <td>{{ proveedor.nombre }}</td>
                </tr>
                <tr v-if="producto.precio_compra">
                  <td><strong>Precio de compra</strong></td>
                  <td>{{ precioCompra | convertPesoArg }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="mt-2">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Costo</th>
                  <th>IVA C.</th>

                  <th>Gan</th>
                  <th>IVA V.</th>

                  <th>Flete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ producto.precioCosto | convertPesoArg}}</td>
                  <td>{{ producto.iva_compra.description }}%</td>


                  <td>{{ producto.ganancia }}%</td>
                  <td>{{ producto.iva.description }}%</td>
                  <td>{{ producto.flete }}%</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "producto.vista.previa",
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    producto: {
      type: Object,
      required: true,
    },
    precioPY: {
      type: Number,
      required: false,
      default: 0,
    },
    marca: {
      required: true,
    },
    proveedor: {
      required: true,
    },
    categoria: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    precioVenta() {
    if (
      this.producto.precioCosto > 0 &&
      this.producto.ganancia > 0 &&
      this.producto.flete >= 0 &&
      this.producto.iva
    ) {
      const flete = this.producto.flete
        ? parseFloat(this.producto.flete) / 100
        : 0;
      const ivaVenta = parseFloat(this.producto.iva.description) / 100;
      const ganancia = parseFloat(this.producto.ganancia) / 100;

      // Calcula el precio de venta redondeado al entero más cercano
      const precioVenta = Math.round(
        this.precioCompra * (1 + ganancia) * (1 + ivaVenta) * (1 + flete)
      );

      // Asigna el valor calculado a producto.precioVenta
      this.producto.precioVenta = precioVenta;
      return precioVenta;
    }

    return null;
  },
  precioCompra() {
  const ivaCompraDescripcion = this.producto.iva_compra
    ? this.producto.iva_compra.description
    : "0";

  // Verificar si el valor es numérico; si no, asumir que es "exento" (0%)
  const ivaCompra = isNaN(parseFloat(ivaCompraDescripcion))
    ? 0
    : parseFloat(ivaCompraDescripcion) / 100;

  return this.producto.precioCosto * (1 + ivaCompra);
}
  },
};
</script>

<style lang="scss" scoped></style>
