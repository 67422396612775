<template>
  <span v-if="remito">
    <layout-remito-registrar>
      <template v-slot:title
        ><div>
          Remito
          <span v-if="remito.numero"> Nº {{ remito.numero }} </span>
          <span v-else> ID {{ remito.id }} </span>
        </div>
        <div></div>
      </template>

      <template v-slot:navigation-controller>
        <v-container>
          <v-divider class="mt-2"></v-divider>
          
          <v-list-item>
           

            <v-list-item-content>
              <v-list-item-title
                v-text="'Número de comprobante'"
              ></v-list-item-title>
              <v-list-item-subtitle>{{
                remito.nroComprobante
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon v-text="'mdi-calendar-range'"></v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="'Fecha de creación'"
              ></v-list-item-title>
              <v-list-item-subtitle>{{
                moment(remito.fechaAlta).format("LL H:mma")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-container>
      </template>
      <template v-slot:button-navigation-controller>
        <compra-detalle-subtotal
          :detalles="detalles_remito"
        ></compra-detalle-subtotal>
      </template>

      <template v-slot:vista-previa>
        <v-card color="transparent" flat>
          <v-container fill-height fluid>
            <v-row align="center" class="mt-2 mb-2 ml-2 mr-2">
              <v-col cols="12">
                <div class="mb-2">Detalles</div>
                <FacturaCompraDetalleList
                  :edit="false"
                  :withIva="true"
                  :detalles="detalles_remito"
                ></FacturaCompraDetalleList>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
      <template v-slot:cliente>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Nº factura </v-list-item-title>
                <v-list-item-subtitle>
                  {{ remito.numero ? remito.numero : "-" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> Sucursal </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    remito.sucursalProveedor ? remito.sucursalProveedor : "-"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </template>
      <template v-slot:detalle-pedido>
        <v-container>
          <info-proveedor :proveedor="proveedor"></info-proveedor>
        </v-container>
      </template>
    </layout-remito-registrar>
  </span>
</template>

<script>
import LayoutRemitoRegistrar from "@/layouts/layout.registrar.vue";
import InfoProveedor from "@/components/proveedor/proveedor.info.vue";
import RemitoCompraDetallesTable from "@/components/compra/remito/remito.compra.detalles.consultar.vue";
import CompraDetalleSubtotal from "@/components/compra/compra.detalle.subtotal.vue";
import FacturaCompraDetalleList from "@/components/compra/factura/FacturaCompraDetallesList.vue";

import { mapActions } from "vuex";

export default {
  name: "remito.compra.consultar.detalle",
  components: {
    FacturaCompraDetalleList,
    InfoProveedor,
    LayoutRemitoRegistrar,
    RemitoCompraDetallesTable,
    CompraDetalleSubtotal,
  },
  $_veeValidate: {
    validator: "new",
  },
  filters: {
    convertPesoArg(value) {
      return `$ ${new Intl.NumberFormat("de-DE").format(value)}`;
    },
  },
  props: {
    tipoComprobante: {
      type: String,
      required: true,
      default: "compras",
    },
  },

  data() {
    return {
      montoActual: 0,
      proveedor: null,
      remito: null,
      sucursal: null,
      nroRemito: null,
      detalles_remito: [],
    };
  },

  methods: {
    ...mapActions("remitos", ["registrar_remito_compra"]),

    fetchPedido(id_remito) {
      var url = `/comprobantes/comprobantes/${id_remito}`;

      this.axios.get(url).then((response) => {
        this.remito = response.data;
        this.detalles_remito = response.data.detalles_comprobante;
        this.proveedor = response.data.proveedor;
      });
    },

    async getMontoActual() {
      const response = await this.axios.get("/sucursales/arqueo/monto");

      this.montoActual = response.data.saldo;
    },
  },

  created() {
    let id = this.$route.params.id;
    this.fetchPedido(id);
  },
  watch: {
    "$route.params": {
      //Llegan parametros unicamente cuando se selecciona un borrador
      handler: function (params) {
        if (Object.keys(params).length == 0) {
          return;
        } else {
          if (params.pedido) {
            this.fetchPedido(params.id);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}
</style>
